import { useMemo } from "react";
import { useInstanceRoute } from "@tecnobit-srl/route-types-instance/lazy";
import { useRedirectRoute } from "@tecnobit-srl/route-types-redirect/lazy";
const useCoreRouteTypes = () => {
  const instanceRoute = useInstanceRoute();
  const redirectRoute = useRedirectRoute();
  return useMemo(
    () => ({
      ...instanceRoute,
      ...redirectRoute
    }),
    [instanceRoute, redirectRoute]
  );
};
export {
  useCoreRouteTypes
};
